<template>
  <div class="confirm">
    <div class="confirm-content">
      <a class="confirm-button __cancel sec-button" @click="$emit('cancel')">
        <span>{{ confirm.cancel.label }}</span>
      </a>
      <a class="confirm-button __accept primary" @click="$emit('accept')">
        <span>{{ confirm.accept.label }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["confirm"],
};
</script>

<style lang="scss" scoped>
.confirm {
  &-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: $mpadding;
  }
}
</style>
